import AppRoutes from "./Components/Layout/Routes/AppRoutes";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <AppRoutes/>
    </React.Fragment>
  );
}

export default App;
