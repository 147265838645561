import strings from "./constant/stringConstant";
import { getAuthCallParams, getNoAuthCallParams, makeCall } from "./service";
import urls from "./UrlConstant";

// Admin login
export async function adminLogin(body, isToast = true) {
  try {
    const callParams = getNoAuthCallParams(strings.POST, body);
    const response = await makeCall(urls.login, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function generateTicketNumber(body, isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.POST, body, isToast);
    const response = await makeCall(urls.generateTicketId, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}


export async function generateLotteryTicket(body, isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.POST, body, isToast);
    const response = await makeCall(urls.generateLottery, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}


export async function getLotteryTickets (body, isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.GET,null, isToast);
    const response = await makeCall(
      
      `${urls.getLotteryTicket}?page=${body.page}&limitPerPage=${body.limit}&totalPages=${body.totalPages}&totalData=${body.totalItems}`, 
      
      callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPurchasedLotteryTickets ( body, isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.GET,null,  isToast);
    const response = await makeCall(
      `${urls.getPurchasedLotteryTicket}?page=${body.page}&limitPerPage=${body.limit}&totalPages=${body.totalPages}&totalData=${body.totalItems}`, 
      callParams, isToast);
    return response;
  } catch (error) {
    throw error;
    // console.log(error)
  }
}


export async function CreatedLotteryTicketsDelete (  isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.DELETE, null,  isToast);
    const response = await makeCall(urls.removeCreatedLottery, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}


// not in use anymore
export async function PurchasedLotteryTicketsDelete (  isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.DELETE, null,  isToast);
    const response = await makeCall(urls.deletePurchasedLottery, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function unPurchasedLotteryTicketsDelete ( isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.DELETE, null,  isToast);
    const response = await makeCall(urls.UnPurchasedLotteryDelete, callParams, isToast);
    return response;
  } catch (error) {
    throw error;
  }
}



export async function getSelectSemInModal (sem, isToast=true) {
  try {
    const callParams = await getAuthCallParams(strings.GET,null,  isToast);
    const response = await makeCall(
      `${urls.getSelectSem}/${sem}`, 
      callParams, isToast);
    return response;
  } catch (error) {
    throw error;
    // console.log(error)
  }
}
